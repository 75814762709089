<template>
  <div>
    <div class="mb-2">{{ $t(i18n.optionsLabel) }}</div>
    <div v-for="(item, index) in value" :key="index">
      <div class="input-group">
        <v-text-field
          v-model="item[inputsModelName + (fieldLang ? `:${fieldLang}` : '')]"
          @input="$emit('input', value)"
          :label="$t(i18n.label)"
          :error="
            !validateState(
              index,
              inputsModelName + (fieldLang ? `:${fieldLang}` : '')
            )
          "
          :error-count="'1'"
          :error-messages="
            !validateState(
              index,
              inputsModelName + (fieldLang ? `:${fieldLang}` : '')
            )
              ? $t(i18n.itemValidation, validations.params)
              : null
          "
        ></v-text-field>
        <v-btn class="mx-2" fab dark color="red" @click="removeItem(index)">
          <v-icon dark>mdi-delete-forever</v-icon>
        </v-btn>
      </div>
    </div>
    <v-btn class="mx-2" fab dark color="indigo" @click="addNewItem">
      <v-icon dark>mdi-plus</v-icon>
    </v-btn>
    <div class="invalid-feedback d-block" v-if="!validateArrayState()">
      {{ $t(i18n.validation, validations.params) }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FormDynamicInput",
  inheritAttrs: false,
  props: {
    /**
     * Model name
     */
    modelName: String,
    /**
     * Inputs model name
     */
    inputsModelName: String,
    /**
     * Field lang
     */
    fieldLang: null,
    /**
     * All Translation i18n keys used
     */
    i18n: Object,
    /**
     * All validations rules and params
     */
    validations: Object,
    /**
     * Vuelidate state $v
     */
    v: {},
    /**
     * V-Model
     */
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {
    ...mapGetters(["getLanguages"])
  },
  methods: {
    addNewItem() {
      if (!Array.isArray(this.value)) {
        this.value = [];
      }

      let newItem = {};

      if (this.fieldLang) {
        this.getLanguages.forEach((lang) => {
          newItem[`${this.inputsModelName}:${lang.lang}`] = "";
        });
      } else {
        newItem[this.inputsModelName] = "";
      }

      this.value.push(newItem);

      this.$emit("input", this.value);
    },

    removeItem(index) {
      this.value.splice(index, 1);

      this.$emit("input", this.value);
    },

    validateState(index, name) {
      if (!this.v.form[this.modelName]["$each"]) {
        return true;
      }

      const { $error } = this.v.form[this.modelName]["$each"][index][name];

      return !$error;
    },

    validateArrayState() {
      const { $error } = this.v.form[this.modelName];

      return !$error;
    }
  }
};
</script>
