<template>
  <div class="input-group">
    <v-text-field
      v-model="value"
      @input="setValue"
      :label="$t(i18n.label)"
      :hide-details="isInvalid ? false : hideDetails ? hideDetails : true"
      :error="isInvalid"
      :error-count="'1'"
      :error-messages="errorMessage"
    ></v-text-field>

    <slot name="button-append"></slot>
    <b-form-invalid-feedback
      v-if="i18n.validation"
      :id="'invalid-' + feedback_id"
    >
      {{ $t(i18n.validation, validations.params) }}
    </b-form-invalid-feedback>
    <b-form-invalid-feedback v-else :id="'invalid-' + feedback_id">
      {{ $t("INVALID", { name: "" }) }}
    </b-form-invalid-feedback>
    <b-form-valid-feedback :id="'valid-' + feedback_id">
      {{ $t("VALID") }}
    </b-form-valid-feedback>
  </div>
</template>

<script>
import i18n from "@/core/plugins/vue-i18n";
export default {
  name: "FormInputVuetify",
  inheritAttrs: false,
  props: {
    /**
     * Validation id of the error
     */
    feedback_id: String,
    /**
     * name of the input
     */
    name: String,
    /**
     * form helper description
     */
    description: String,
    /**
     * All Translation i18n keys used
     */
    i18n: Object,
    /**
     * All validations rules and params
     */
    validations: Object,
    /**
     * Validate State validation
     */
    validateState: Boolean,
    /**
     * V-Model
     */
    value: {}
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {
    isInvalid: function () {
      if (this.validateState == false) {
        return true;
      }
      return false;
    },
    errorMessage: function () {
      if (this.validateState == false) {
        return i18n.t(this.i18n.validation, this.validations.params);
      }
      return "";
    }
  },
  methods: {
    setValue(value) {
      //  trigger a mutation, or dispatch an action
      this.$emit("input", value);
    }
  }
};
</script>
