<template>
  <v-datetime-picker
    :label="$t(i18n.label)"
    :dateFormat="dateFormat"
    :timeFormat="timeFormat"
    v-model="tempDateTime"
    :text-field-props="textFieldProps"
    :date-picker-props="dateProps"
    :time-picker-props="timeProps"
    :disabled="isDisabled"
  >
  </v-datetime-picker>
</template>
<script>
import i18nService from "@/core/services/i18n.service.js";
import moment from "moment";
export default {
  name: "FormDateTimePicker",
  inheritAttrs: false,
  props: {
    dateTime: String,
    /**
     * All Translation i18n keys used
     */
    i18n: Object,
    /**
     * Is clearable date or not
     */
    dateFormat: {
      type: String,
      default: "yyyy-MM-dd"
    },
    timeFormat: {
      type: String,
      default: "HH:mm:ss"
    },
    momentFormat: {
      type: String,
      default: "YYYY-MM-DD HH:mm:ss"
    },
    timeUseSeconds: {
      type: Boolean,
      default: true
    },
    max: {
      type: String,
      default: moment().format("YYYY-MM-DD")
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isClearable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modal: false
    };
  },
  components: {},
  beforeMount() {},
  mounted() {},
  computed: {
    activeLanguage() {
      return i18nService.getActiveLanguage();
    },
    tempDateTime: {
      get: function() {
        return this.dateTime;
      },
      set: function(newValue) {
        let formattedValue = null;
        if (newValue != null && newValue != "") {
          formattedValue = moment(newValue).format(this.momentFormat);
        }
        this.$emit("update:dateTime", formattedValue);
      }
    },
    textFieldProps() {
      return {
        prependIcon: "mdi-calendar",
        hideDetails: true,
        readonly: true,
        disabled: this.isDisabled
      };
    },
    dateProps() {
      return {
        noTitle: true,
        firstDayOfWeek: "1",
        max: this.max
      };
    },
    timeProps() {
      return {
        useSeconds: this.timeUseSeconds,
        format: "24hr",
        scrollable: true
      };
    },
    maxDate() {
      return moment().format("YYYY-MM-DD");
    }
  },
  methods: {
    submit(closePicker = true) {
      if (closePicker) {
        this.modal = false;
      }
    }
  }
};
</script>
