<template>
  <div>
    <tiptap-vuetify
      :value="value"
      @input="setValue"
      :extensions="extensions"
      :toolbar-attributes="toolbarAttributes"
    />

    <v-expansion-panels class="mt-5" flat multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3 class="mt-4 primary--text">Preview</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-divider />
          <div class="tiptap-vuetify-editor__content" v-html="value" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3 class="mt-2 primary--text">HTML</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-divider />
          <div class="mt-3">{{ value }}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";
export default {
  name: "FormRichTextarea",
  inheritAttrs: false,
  props: {
    /**
     * Validation id of the error
     */
    feedback_id: String,
    /**
     * name of the input
     */
    name: String,
    /**
     * All Translation i18n keys used
     */
    i18n: Object,
    /**
     * All validations rules and params
     */
    validations: Object,
    /**
     * Validate State validation
     */
    validateState: Boolean,
    /**
     * V-Model
     */
    value: {}
  },
  data() {
    return {
      // declare extensions you want to use
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3]
            }
          }
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      toolbarAttributes: {
        // color: "primary",
        // dark: false
      }
    };
  },
  components: { TiptapVuetify },
  mounted() {},
  computed: {},
  methods: {
    setValue(value) {
      //  trigger a mutation, or dispatch an action
      this.$emit("input", value);
    }
  }
};
</script>
