<template>
  <div class="mb-5">
    <ul class="nav nav-tabs">
      <li
        class="nav-item nav-link pointer"
        v-for="(lang, langIndex) in tabLanguages"
        :key="'tab-language-' + langIndex"
        :class="activeLanguage == lang.lang ? 'active' : ''"
        href="#"
        @click="onTabLanguageChanged(lang)"
      >
        <span
          v-if="checkIfLangTabHasError(lang.lang)"
          class="text-danger nav-icon"
        >
          <i class="flaticon-exclamation-1 text-danger"></i>
        </span>
        <span class="nav-icon">
          <img alt="" class="h-20px w-20px rounded-sm" :src="lang.flag" />
        </span>
        <span>{{ lang.lang }}</span>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
<script>
export default {
  name: "FormTabLanguages",
  props: {
    tabLanguages: {
      type: Array,
      required: true
    },

    activeLanguage: {
      type: String,
      required: true
    },

    tabErrors: {
      type: Array,
      required: false
    }
  },

  methods: {
    onTabLanguageChanged(lang) {
      this.$emit("tabLanguageChanged", lang);
    },
    checkIfLangTabHasError(lang) {
      let vm = this;

      return (
        vm.tabErrors &&
        (vm.tabErrors.includes("all") || vm.tabErrors.includes(lang))
      );
    }
  }
};
</script>
