var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t(_vm.i18n.optionsLabel)))]),_vm._l((_vm.value),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"input-group"},[_c('v-text-field',{attrs:{"label":_vm.$t(_vm.i18n.label),"error":!_vm.validateState(
            index,
            _vm.inputsModelName + (_vm.fieldLang ? `:${_vm.fieldLang}` : '')
          ),"error-count":'1',"error-messages":!_vm.validateState(
            index,
            _vm.inputsModelName + (_vm.fieldLang ? `:${_vm.fieldLang}` : '')
          )
            ? _vm.$t(_vm.i18n.itemValidation, _vm.validations.params)
            : null},on:{"input":function($event){return _vm.$emit('input', _vm.value)}},model:{value:(item[_vm.inputsModelName + (_vm.fieldLang ? `:${_vm.fieldLang}` : '')]),callback:function ($$v) {_vm.$set(item, _vm.inputsModelName + (_vm.fieldLang ? `:${_vm.fieldLang}` : ''), $$v)},expression:"item[inputsModelName + (fieldLang ? `:${fieldLang}` : '')]"}}),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"red"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete-forever")])],1)],1)])}),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"indigo"},on:{"click":_vm.addNewItem}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1),(!_vm.validateArrayState())?_c('div',{staticClass:"invalid-feedback d-block"},[_vm._v(" "+_vm._s(_vm.$t(_vm.i18n.validation, _vm.validations.params))+" ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }