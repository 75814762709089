<template>
  <TabLanguages
    :tabLanguages="getLanguages"
    :activeLanguage="getTabLanguage.lang"
    :tabErrors="tabErrors"
    @tabLanguageChanged="onTabLanguageChanged"
  />
</template>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
<script>
import TabLanguages from "@/core/components/TabLanguages.vue";
import { SET_TAB_LANGUAGE } from "@/core/services/store/lang.module";
import { mapGetters } from "vuex";
export default {
  name: "FormTabLanguages",
  props: {
    /**
     * Vuelidate state $v
     */
    v: {}
  },
  data() {
    return {
      tabErrors: []
    };
  },
  components: { TabLanguages },
  mounted() {},
  watch: {
    v: {
      deep: true,

      // We have to move our method to a handler field
      handler() {
        let vm = this;
        vm.tabErrors = [];
        if (vm.v.$anyDirty == true) {
          for (const [key, value] of Object.entries(vm.v.form.$model)) {
            if (key.includes(":")) {
              //translatable
              var keyLang = key.split(":")[1];
              if (vm.v.form[key] && vm.v.form[key].$invalid == true) {
                if (!vm.tabErrors.includes(keyLang)) {
                  vm.tabErrors.push(keyLang);
                }
              }
            } else {
              //plain
              if (vm.v.form[key] && vm.v.form[key].$invalid == true) {
                if (!vm.tabErrors.includes("all")) {
                  vm.tabErrors.push("all");
                }
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(["getLanguages", "getTabLanguage"])
  },
  methods: {
    onTabLanguageChanged(lang) {
      // this.tabLanguage = lang;
      this.$store.commit(SET_TAB_LANGUAGE, lang);
    },
    checkIfLangTabHasError(lang) {
      let vm = this;
      let hasError = false;
      if (vm.tabErrors.includes("all") || vm.tabErrors.includes(lang)) {
        hasError = true;
      }
      return hasError;
    }
  }
};
</script>
