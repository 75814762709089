<template>
  <b-col
    :sm="schemaField.colSm ? schemaField.colSm : ''"
    v-if="
      !('hidden' in schemaField) ||
      ('hidden' in schemaField && schemaField.hidden == false)
    "
  >
    <b-form-group
      v-if="
        schemaField.type != 'datetimepicker' &&
        schemaField.type != 'datepickerbase' &&
        schemaField.type != 'datepicker' &&
        schemaField.type != 'select' &&
        schemaField.type != 'input' &&
        schemaField.type != 'dynamic-input'
      "
      v-bind:id="schemaField.groupId"
      v-bind:label="$t(schemaField.i18n.label)"
      v-bind:label-for="schemaField.id"
      v-bind:description="$t(schemaField.i18n.description)"
    >
      <FormRichTextarea
        v-if="schemaField.type == 'richtextarea'"
        :id="schemaField.id"
        :type="schemaField.inputType"
        :name="schemaField.model"
        :required="schemaField.required"
        :feedback_id="schemaField.feedback_id"
        :i18n="schemaField.i18n"
        :validations="schemaField.validations"
        :validateState="validateState(schemaField.model)"
        v-model="form[schemaField.model]"
      ></FormRichTextarea>

      <FormSwitch
        v-if="schemaField.type == 'switch'"
        :id="schemaField.id"
        :type="schemaField.inputType"
        :name="schemaField.model"
        v-model="form[schemaField.model]"
      ></FormSwitch>

      <FormRadio
        v-if="schemaField.type == 'radio'"
        :id="schemaField.id"
        :type="schemaField.inputType"
        :name="schemaField.model"
        :options="schemaField.options"
        :rowHorizontal="schemaField.rowHorizontal"
        :clearable="schemaField.clearable"
        :feedback_id="schemaField.feedback_id"
        :i18n="schemaField.i18n"
        :validations="schemaField.validations"
        :validateState="validateState(schemaField.model)"
        v-model="form[schemaField.model]"
      ></FormRadio>
    </b-form-group>

    <FormTextarea
      v-if="schemaField.type == 'textarea'"
      :id="schemaField.id"
      :type="schemaField.inputType"
      :name="schemaField.model"
      :required="schemaField.required"
      :feedback_id="schemaField.feedback_id"
      :i18n="schemaField.i18n"
      :validations="schemaField.validations"
      :validateState="validateState(schemaField.model)"
      v-model="form[schemaField.model]"
    ></FormTextarea>

    <FormInputVuetify
      v-if="schemaField.type == 'input'"
      :id="schemaField.id"
      :type="schemaField.inputType"
      :name="schemaField.model"
      :description="schemaField.description"
      :required="schemaField.required"
      :feedback_id="schemaField.feedback_id"
      :i18n="schemaField.i18n"
      :validations="schemaField.validations"
      :validateState="validateState(schemaField.model)"
      v-model="form[schemaField.model]"
    ></FormInputVuetify>

    <FormSelect
      v-if="schemaField.type == 'select'"
      :id="schemaField.id"
      :type="schemaField.inputType"
      :name="schemaField.model"
      :options="schemaField.options"
      :clearable="schemaField.clearable"
      :reduceKey="schemaField.reduceKey"
      :feedback_id="schemaField.feedback_id"
      :i18n="schemaField.i18n"
      :validations="schemaField.validations"
      :validateState="validateState(schemaField.model)"
      v-model="form[schemaField.model]"
    ></FormSelect>

    <FormDateTimePicker
      v-if="schemaField.type == 'datetimepicker'"
      :id="schemaField.id"
      :type="schemaField.inputType"
      :name="schemaField.model"
      :i18n="schemaField.i18n"
      :dateTime.sync="form[schemaField.model]"
    ></FormDateTimePicker>

    <FormDatepicker
      v-if="schemaField.type == 'datepicker'"
      :id="schemaField.id"
      :type="schemaField.inputType"
      :name="schemaField.model"
      :i18n="schemaField.i18n"
      v-model="form[schemaField.model]"
    ></FormDatepicker>

    <FormDatepickerBase
      v-if="schemaField.type == 'datepickerbase'"
      :id="schemaField.id"
      :type="schemaField.inputType"
      :name="schemaField.model"
      :i18n="schemaField.i18n"
      :date.sync="form[schemaField.model]"
    ></FormDatepickerBase>

    <FormDynamicInput
      v-if="schemaField.type == 'dynamic-input'"
      :modelName="schemaField.model"
      :inputsModelName="schemaField.inputsModelName"
      :i18n="schemaField.i18n"
      :validations="schemaField.validations"
      :v="v"
      v-model="form[schemaField.model]"
    ></FormDynamicInput>
  </b-col>
</template>

<script>
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import FormSwitch from "@/view/content/forms/components/FormSwitch.vue";
import FormInputVuetify from "@/view/content/forms/components/FormInputVuetify.vue";
import FormTextarea from "@/view/content/forms/components/FormTextarea.vue";
import FormRichTextarea from "@/view/content/forms/components/FormRichTextarea.vue";
import FormRadio from "@/view/content/forms/components/FormRadio.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";
import FormDateTimePicker from "@/view/content/forms/components/FormDateTimePicker.vue";
import FormDynamicInput from "@/view/content/forms/components/FormDynamicInput.vue";
import FormDatepickerBase from "@/view/content/forms/components/FormDatepickerBase.vue";

export default {
  name: "FormBuilderInnerPlain",
  props: {
    /**
     * All rows out of the model needed for the form.
     * e.g. id, email, name, phone, active
     */
    form: Object,
    /**
     * Field related parameters.
     * e.g. id, type, inputType, label, model, min, max, required, hint, validator, placeholder, default, clearable, readonly
     */
    schemaField: Object,
    /**
     * index from v-for loop of all fields
     */
    schemaFieldIndex: Number,
    /**
     * Vuelidate state $v
     */
    v: {}
  },
  data() {
    return {};
  },
  components: {
    FormDatepickerBase,
    FormSelect,
    FormSwitch,
    FormInputVuetify,
    FormTextarea,
    FormRichTextarea,
    FormRadio,
    FormDatepicker,
    FormDateTimePicker,
    FormDynamicInput
  },
  mounted() {},
  computed: {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.v.form[name];
      return $dirty ? !$error : null;
    }
  }
};
</script>
